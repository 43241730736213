import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import transactionService from './transactionService'
import { handleFulfilledList } from '../masters/mastersSlice'
import { getApiError } from 'src/utils/helper'

const initialState = {
  orderList: [],
  batchOrderList: [],
  returnProcessList: [],
  selectedReturnProcess: null,
  processOrders: [], // for other processes
  processOrderList: [], // for update process
  processAssociateStyles: [], // for update process
  processAssociateColors: [], // for update process
  processAssociateSizes: [], // for update process
  processQuantityList: [], // for update process
  countries: [],
  selectedOrder: null,
  selectedOrderStyles: [],
  batchList: [],
  upcMatrix: [],
  orderQuantities: [],
  palletList: [],
  importLogsList: [],
  selectedPallet: null,
  palletStylesList: [],
  palletStyleColorsList: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  data: [],
  page: 1,
  limit: 10,
  searchKeyword: '',
  isListCompleted: false,
}

export const fetchOrdersList = createAsyncThunk(
  'transaction/fetchOrdersList',
  async (
    {
      page,
      limit,
      keyword,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'orderNo',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.listOrders({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchBatchOrdersList = createAsyncThunk(
  'transaction/fetchBatchOrdersList',
  async (
    {
      page,
      limit,
      keyword,
      forBatch = true,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.listOrders({
        page,
        limit,
        keyword,
        isPaginate,
        forBatch,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchOrderBatch = createAsyncThunk(
  'transaction/fetchOrderBatch',
  async (
    {
      page,
      limit,
      keyword,
      forBatch,
      isPaginate = true,
      isFirst = false,
      order = 'DESC',
      orderBy = 'createdAt',
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.listBatch({
        page,
        limit,
        keyword,
        isPaginate,
        forBatch,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchOrderById = createAsyncThunk(
  'transaction/fetchOrderById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await transactionService.getOrderById(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchProcessOrderById = createAsyncThunk(
  'transaction/fetchProcessOrderById',
  async ({ processOrderId, type }, { rejectWithValue }) => {
    try {
      const response = await transactionService.getProcessOrderById(processOrderId, type)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchOrderStyles = createAsyncThunk(
  'transaction/fetchOrderStyles',
  async (orderNo, { rejectWithValue }) => {
    try {
      const response = await transactionService.getOrderStyles(orderNo)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchProcessOrders = createAsyncThunk(
  'transaction/fetchProcessOrders',
  async (processOrderType, { rejectWithValue }) => {
    try {
      const response = await transactionService.getProcessOrders(processOrderType)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchProcessOrdersList = createAsyncThunk(
  'transaction/fetchProcessOrdersList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await transactionService.getProcessOrdersList()
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchProcessAssociations = createAsyncThunk(
  'transaction/fetchProcessAssociations',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await transactionService.getProcessAssociations(payload)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchProcessQuantityList = createAsyncThunk(
  'transaction/fetchProcessQuantityList',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await transactionService.getProcessQuantityList(payload)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchUPCMatrix = createAsyncThunk(
  'transaction/fetchUPCMatrix',
  async ({ orderId, styleId, colorId, designId }, { rejectWithValue }) => {
    try {
      const response = await transactionService.getUPCMatrix(orderId, styleId, colorId, designId)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const createUPC = createAsyncThunk(
  'transaction/createUPC',
  async (upcData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createUPC(upcData)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const createBatch = createAsyncThunk(
  'transaction/createBatch',
  async (batchData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createBatch(batchData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchPalletList = createAsyncThunk(
  'transaction/fetchPalletList',
  async (
    { page, limit, keyword, isPaginate = true, order = 'DESC', orderBy = 'createdAt' },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.listPallet({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchImportLogs = createAsyncThunk(
  'transaction/fetchImportLogs',
  async ({ page, limit, keyword, isPaginate, order, orderBy }, { rejectWithValue }) => {
    try {
      const response = await transactionService.listImportLogs({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchPalletById = createAsyncThunk(
  'transaction/fetchPalletById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await transactionService.getPalletById({ palletId: id })
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updatePallet = createAsyncThunk(
  'transaction/updatePallet',
  async (palletData, { rejectWithValue }) => {
    try {
      const response = await transactionService.updatePallet(palletData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchPalletListStyles = createAsyncThunk(
  'transaction/fetchPalletListStyles',
  async ({ palletId }, { rejectWithValue }) => {
    try {
      const response = await transactionService.listPalletStyles({
        palletId,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchPalletListStyleColors = createAsyncThunk(
  'transaction/fetchPalletListStyleColors',
  async ({ palletId, styleId }, { rejectWithValue }) => {
    try {
      const response = await transactionService.listPalletStyleColors({
        palletId,
        styleId,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const createPallet = createAsyncThunk(
  'transaction/createPallet',
  async (palletData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createPallet(palletData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createPalletImport = createAsyncThunk(
  'transaction/createPalletImport',
  async (palletData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createPalletImport(palletData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createPaymentImport = createAsyncThunk(
  'transaction/createPaymentImport',
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createPaymentImport(paymentData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createPalletTransaction = createAsyncThunk(
  'transaction/createPalletTransaction',
  async (palletData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createPalletTransaction(palletData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createPalletMoveAndUpdate = createAsyncThunk(
  'transaction/createPalletMoveAndUpdate',
  async (palletData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createPalletMoveAndUpdate(palletData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const createProcess = createAsyncThunk(
  'transaction/createProcess',
  async (processData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createProcess(processData)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const createReturnProcess = createAsyncThunk(
  'transaction/createReturnProcess',
  async (processData, { rejectWithValue }) => {
    try {
      const response = await transactionService.createReturnProcess(processData)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchReturnProcessList = createAsyncThunk(
  'transaction/fetchReturnProcessList',
  async (
    { page, limit, keyword, isPaginate = true, order = 'DESC', orderBy = 'createdAt' },
    { rejectWithValue },
  ) => {
    try {
      const response = await transactionService.listReturnProcess({
        page,
        limit,
        keyword,
        isPaginate,
        order,
        orderBy,
      })
      return response
    } catch (error) {
      console.debug('error ==> ', error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchReturnProcessById = createAsyncThunk(
  'transaction/fetchReturnProcessById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await transactionService.getReturnProcessById(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateProcessPublish = createAsyncThunk(
  'transaction/updateProcessPublish',
  async (processData, { rejectWithValue }) => {
    try {
      const response = await transactionService.updateProcessPublish(processData)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const editBatch = createAsyncThunk(
  'transaction/editBatch',
  async (batchData, { rejectWithValue }) => {
    try {
      const response = await transactionService.editBatch(batchData)
      return response
    } catch (error) {
      console.debug(error.response.data)
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateOrder = createAsyncThunk(
  'transaction/updateOrder',
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await transactionService.updateOrder(orderData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchCountry = createAsyncThunk(
  'transaction/fetchCountry',
  async (_, { rejectWithValue }) => {
    try {
      const response = await transactionService.listCountry()
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchPalletOrderQuantities = createAsyncThunk(
  'transaction/fetchPalletOrderQuantities',
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await transactionService.getPalletOrderQuantities(orderData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
export const fetchOrderQuantities = createAsyncThunk(
  'transaction/fetchOrderQuantities',
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await transactionService.getOrderQuantities(orderData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteBatchOrders = createAsyncThunk(
  'transaction/deleteBatchOrders',
  async (id, { rejectWithValue }) => {
    try {
      const response = await transactionService.deleteBatch(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const cancelPulling = createAsyncThunk(
  'transaction/cancelPulling',
  async (id, { rejectWithValue }) => {
    try {
      const response = await transactionService.cancelPullingProcess(id)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false
      state.isLoading = false
      state.isSuccess = false
      state.message = ''
    },
    setIsListCompleted: (state, action) => {
      state.isListCompleted = action.payload
    },
    setProcessOrdersListEmpty: (state, action) => {
      state.processOrders = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'orderList')
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchBatchOrdersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBatchOrdersList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'batchOrderList')
      })
      .addCase(fetchBatchOrdersList.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchOrderBatch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderBatch.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'batchList')
      })
      .addCase(fetchOrderBatch.rejected, (state, action) => {
        console.debug('Rejected', action)
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchOrderById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.selectedOrder = action.payload
      })
      .addCase(fetchOrderById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchProcessOrderById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProcessOrderById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.selectedOrder = action.payload
      })
      .addCase(fetchProcessOrderById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchOrderStyles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderStyles.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.selectedOrderStyles = action.payload
      })
      .addCase(fetchOrderStyles.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchProcessOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProcessOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.processOrders = action.payload
      })
      .addCase(fetchProcessOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchProcessOrdersList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProcessOrdersList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.processOrderList = action.payload
      })
      .addCase(fetchProcessOrdersList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchProcessAssociations.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProcessAssociations.fulfilled, (state, action) => {
        console.debug({ action })
        state.isLoading = false
        state.isError = false
        switch (action?.meta?.arg?.type) {
          case 'style':
            state.processAssociateStyles = action.payload

            break
          case 'color':
            state.processAssociateColors = action.payload

            break
          case 'size':
            state.processAssociateSizes = action.payload

            break

          default:
            break
        }
      })
      .addCase(fetchProcessAssociations.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchProcessQuantityList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProcessQuantityList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.processQuantityList = action.payload
      })
      .addCase(fetchProcessQuantityList.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchUPCMatrix.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUPCMatrix.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.upcMatrix = action.payload
      })
      .addCase(fetchUPCMatrix.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchCountry.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'countries')
      })
      .addCase(fetchCountry.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPalletOrderQuantities.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletOrderQuantities.fulfilled, (state, action) => {
        state.isLoading = false
        state.orderQuantities = action?.payload?.data
      })
      .addCase(fetchPalletOrderQuantities.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchOrderQuantities.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderQuantities.fulfilled, (state, action) => {
        state.isLoading = false
        state.orderQuantities = action?.payload?.data
      })
      .addCase(fetchOrderQuantities.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(createUPC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUPC.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createUPC.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(createBatch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createBatch.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createBatch.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchImportLogs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchImportLogs.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'importLogsList')
      })
      .addCase(fetchImportLogs.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPalletList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'palletList')
      })
      .addCase(fetchPalletList.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPalletById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.selectedPallet = action.payload
      })
      .addCase(fetchPalletById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updatePallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updatePallet.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(fetchPalletListStyles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletListStyles.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = false
        state.message = ''
        state.palletStylesList = action.payload?.data
      })
      .addCase(fetchPalletListStyles.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchPalletListStyleColors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPalletListStyleColors.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = false
        state.message = ''
        state.palletStyleColorsList = action.payload?.data
      })
      .addCase(fetchPalletListStyleColors.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(createPallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createPallet.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(createPalletImport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPalletImport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createPalletImport.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(createPaymentImport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPaymentImport.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createPaymentImport.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(createPalletTransaction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPalletTransaction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createPalletTransaction.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(createPalletMoveAndUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPalletMoveAndUpdate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createPalletMoveAndUpdate.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(createProcess.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createProcess.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createProcess.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchReturnProcessList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReturnProcessList.fulfilled, (state, action) => {
        handleFulfilledList(state, action, 'returnProcessList')
      })
      .addCase(fetchReturnProcessList.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(fetchReturnProcessById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReturnProcessById.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.selectedReturnProcess = action.payload
      })
      .addCase(fetchReturnProcessById.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(createReturnProcess.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createReturnProcess.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(createReturnProcess.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(updateProcessPublish.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateProcessPublish.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(updateProcessPublish.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(editBatch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editBatch.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(editBatch.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
      .addCase(deleteBatchOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteBatchOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(deleteBatchOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })

      .addCase(cancelPulling.pending, (state) => {
        state.isLoading = true
      })
      .addCase(cancelPulling.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true
        state.message = action.payload?.message
      })
      .addCase(cancelPulling.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = getApiError(action.payload?.errors, action.payload?.message)
      })
  },
})

export const { reset, setIsListCompleted, setProcessOrdersListEmpty } = transactionSlice.actions
export default transactionSlice.reducer
